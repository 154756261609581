   
                           
  
                                                                
                                                             
   

export default class UnicornHttp {
  globalAxios;
  CanceledError;

     
                          
     
  axios;

  static get is() { return 'http'; }

  static install(app, options) {
    app.$http = new this(app);
  }

  constructor(app) {
    this.app = app;

    this.config = {
      customMethod: false,
    };

    this.data = {};
  }

  get getSelf() {
    return this;
  }

  importAxios() {
    return this.app.import('@axios');
  }

     
                           
     
  getGlobalAxios() {
    if (!this.globalAxios) {
      this.globalAxios = this.importAxios();
    }

    return this.globalAxios;
  }

     
                           
     
  createHttp() {
    return this.getGlobalAxios().then(() => {
      this.CanceledError = axios.CanceledError;
      return this.axios = axios.create(this.options.axios || {});
    });
  }

     
                                      
     
  getHttp() {
    if (this.axios) {
      return Promise.resolve(this.axios);
    }

    return this.createHttp().then((axios) => this.axios = axios);
  }

     
                                 
     
  prepareAxios(axios) {
    axios.interceptors.request.use((config) => {
      config.headers['X-CSRF-Token'] = this.app.data('csrf-token');

      if (config.url && config.url.startsWith('@')) {
        config.url = this.app.route(config.url);
      }

      return config;
    });
  }

  requestMiddleware(callback) {
    return this.getHttp().then(axios => axios.interceptors.request.use(callback));
  }

  responseMiddleware(callback) {
    return this.getHttp().then(axios => axios.interceptors.response.use(callback));
  }

  ready() {
    super.ready();
  }

     
                        
    
                        
                                        
    
                                      
     
  get(url, options = {}) {
    options.url = url;
    options.method = 'GET';

    return this.request(options);
  }

     
                         
    
                        
                      
                                        
    
                                      
     
  post(url, data, options = {}) {
    options.url = url;
    options.method = 'POST';
    options.data = data;

    return this.request(options);
  }

     
                        
    
                        
                      
                                        
    
                                      
     
  put(url, data, options = {}) {
    options.url = url;
    options.method = 'PUT';
    options.data = data;

    return this.request(options);
  }

     
                          
    
                        
                      
                                        
    
                                      
     
  patch(url, data, options = {}) {
    options.url = url;
    options.method = 'PATCH';
    options.data = data;

    return this.request(options);
  }

     
                           
    
                        
                      
                                        
    
                                      
     
  'delete'(url, data, options = {}) {
    options.url = url;
    options.method = 'DELETE';
    options.data = data;

    return this.request(options);
  }

     
                         
    
                        
                                        
    
                                      
     
  head(url, options = {}) {
    options.url = url;
    options.method = 'HEAD';

    return this.request(options);
  }

     
                            
    
                        
                                        
    
                                      
     
  options(url, options = {}) {
    options.url = url;
    options.method = 'OPTIONS';

    return this.request(options);
  }

  isCancel(cancel) {
    return axios.isCancel(cancel);
  }

     
                  
    
                                        
    
                                      
     
  request(options) {
    return this.getHttp()
      .then(axiosInstance => {
        this.prepareAxios(axiosInstance);

        return axiosInstance(options);
      })
      .catch(
                                    
        (e) => {
          e.originMessage = e.message;

          if (e.response?.data?.message) {
            e.message = e.response.data.message;
          }

          return Promise.reject(e);
        }
      );

                                
                        
                                
      
                                        
                             
                                 
        
      
                                                   
      
                        
                                        
                                        
        
      
                                                        
                                      
        
      
                                                               
               
                                                                
      
                                                       
                                   
      
                                                                                         
                                                                
                                                   
                                  
        
      
                                     
              
            
                              
                            
                            
                    
         
      
                                             
                                
                                         
                                                          
                                                      
                   
                                                                 
            
            
  }

     
                                              
    
                                                                                 
    
                               
    
                             
     
  customMethod(useHeader = true) {
    const clone = this;
    clone.axios = null;

    return clone.requestMiddleware((config) => {
      if (useHeader) {
        config.headers['X-HTTP-Method-Override'] = config;
      } else if (typeof config.data === 'object') {
        config.data['_method'] = config.method;
      } else if (typeof config.data === 'string') {
        if (config.data.includes('?')) {
          config.data += '&_method=' + config.method;
        } else {
          config.data += '?_method=' + config.method;
        }
      }

      config.method = 'POST';

      return config;
    }).then(() => clone);
  }
}
