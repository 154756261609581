   
                           
  
                                                   
                          
   

import { defaultsDeep } from 'lodash-es';

let imported = false;

export default class UnicornTinymce {
     
                                              
     
  instances = {};

  hooks = [];

  static install(app) {
    app.$ui.tinymce = new this(app.$ui);
  }

  constructor(ui) {
    this.ui = ui;
    this.app = ui.app;
  }

     
                                
     
  loadTinymce() {
    return this.app.import('@tinymce')
      .then((tinymce) => {
        if (imported) {
          return tinymce;
        }

        imported = true;

        for (const hook of this.hooks) {
          hook(tinymce);
        }

        return registerDragPlugin().then(() => tinymce);
      });
  }

     
                                                 
                    
     
  configure(callback) {
    this.hooks.push(callback);

    return this;
  }

     
                             
                            
                             
     
  init(selector, options = {}) {
    return this.loadTinymce().then(() => {
      return this.instances[selector] = this.create(document.querySelector(selector), options);
    });
  }

     
                             
                             
     
  get(selector) {
    return this.instances[selector];
  }

     
                                
                          
                             
     
  create(ele, options = {}) {
    return new TinymceEditor(ele, options, this.app);
  }
}

export class TinymceEditor {
  static defaultOptions = {

  };

     
                   
     
  editor;

     
    
                             
                            
                         
     
  constructor(element, options, app) {
    this.app = app;
    this.element = element;

    options.target = element;

    this.options = defaultsDeep(
      {},
      this.prepareOptions(options, tinymce.majorVersion),
      {
        unicorn: {
          stack_name: 'uploading'
        }
      }
    );

    tinymce.init(this.options).then((editor) => {
      this.editor = editor[0];
    });
  }

     
                      
     
  getEditor() {
    return this.editor;
  }

  prepareOptions(options, verion = '6') {
    const defaults = {};

    if (options.images_upload_url) {
      defaults.paste_data_images = true;
      defaults.remove_script_host = false;
      defaults.relative_urls = false;

      if (Number(verion) >= 6) {
        defaults.images_upload_handler = (blobInfo, progress) =>
          this.imageUploadHandler(blobInfo, progress);
      } else {
        options.plugins.push('paste');

        defaults.images_upload_handler = (blobInfo, success, failure, progress) =>
          this.imageUploadHandler(blobInfo, progress)
            .then((url) => {
              success(url);
              return url;
            })
            .catch((e) => {
              failure(e.message, { remove: true });
              throw e;
            });
      }
    }

                                                                                     

    defaults.plugins = defaults.plugins || [];
    
    defaults.setup = (editor) => {
      editor.on('change', () => {
        tinymce.triggerSave();
      });
    };

    options = defaultsDeep({}, options, defaults);

    if (options.plugins.indexOf('unicorndragdrop') === -1) {
      options.plugins.push('unicorndragdrop');
    }

    return options;
  }

     
                         
     
  insert(text) {
    return this.editor.insertContent(text);
  }

     
                      
     
  getValue() {
    return this.editor.getContent();
  }

     
                         
                      
     
  setValue(text) {
    return this.editor.setContent(text);
  }

                                                
                                                     
                                          
                                    
    
                                       
                                                  
        
    
                                        
    
                                     
                                    
    
                                         
                                      
                                                        
                                                                          
                                                      
                                                               
                                   
    
                                                                                  
                                                                               
           
                                    
                        
         
    
                     
      

     
                             
                               
                               
     
  imageUploadHandler(blobInfo, progress) {
    const element = this.element;

    element.dispatchEvent(new CustomEvent('upload-start'));

    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    const stack = u.stack(this.options.unicorn.stack_name);
    stack.push(true);

    return u.$http.post(
      this.options.images_upload_url,
      formData,
      {
        withCredentials: false,
        onUploadProgress: (e) => {
          progress(e.loaded / e.total * 100);
        }
      }
    )
      .then((res) => {
        element.dispatchEvent(new CustomEvent('upload-success'));

        return res.data.data.url;
      })
      .catch((e) => {
        const message = e?.response?.data?.message || e.message;
        console.error(e?.response?.data?.message || e.message, e);
        element.dispatchEvent(new CustomEvent('upload-error', { detail: e }));

        return Promise.reject({ message, remove: true });
      })
      .finally(() => {
        element.dispatchEvent(new CustomEvent('upload-complete'));
        stack.pop();
      });
  }
}

function registerDragPlugin() {
  tinymce.PluginManager.add('unicorndragdrop', function(editor) {
                                 
    tinyMCE.DOM.bind(document, 'dragleave', function(e) {
      e.stopPropagation();
      e.preventDefault();
      tinyMCE.activeEditor.contentAreaContainer.style.transition = 'all .3s';
      tinyMCE.activeEditor.contentAreaContainer.style.borderWidth = '';

      return false;
    });

    if (typeof FormData !== 'undefined') {

                       
      editor.on('dragenter', e => {
        e.stopPropagation();
        return false;
      });

                                                    
      editor.on('dragover', e => {
        e.preventDefault();
        tinyMCE.activeEditor.contentAreaContainer.style.transition = 'all .3s';
        tinyMCE.activeEditor.contentAreaContainer.style.border = '3px dashed rgba(0, 0, 0, .35)';

        return false;
      });

      editor.on('drop', e => {
        editor.contentAreaContainer.style.borderWidth = '';
        editor.contentAreaContainer.style.borderWidth = '';
      });
    }
  });

  return Promise.resolve();
}
