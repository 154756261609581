   
                         
  
                                                   
                          
   

export default class UnicornQueue {
     
                                            
     
  queues = {};

  static is = 'queue';

  static install(app, options = {}) {
    const $queue = app.$queue = new this(app, options);

    app.queue = $queue.get.bind($queue);
  }

  constructor(app, options = {}) {
    this.app = app;
    this.$options = options;
  }

     
                               
                           
     
  create(maxRunning = 1) {
    if (name == null) {
      throw new Error('Please provide a name.');
    }

    return new SimpleQueue(maxRunning);
  }

     
                         
                               
                           
     
  get(name, maxRunning = 1) {
    if (name == null) {
      throw new Error('Please provide a name.');
    }

    if (!this.queues[name]) {
      this.queues[name] = this.create(maxRunning);
    }

    return this.queues[name];
  }

     
                         
                               
                            
     
  set(name, queue) {
    if (name == null) {
      throw new Error('Please provide a name.');
    }

    this.queues[name] = queue;

    return this;
  }

     
                         
                            
     
  remove(name) {
    delete this.queues[name];

    return this;
  }

     
                                          
     
  all() {
    return this.queues;
  }
}

export class SimpleQueue {
  items = [];

  maxRunning = 1;

  currentRunning = 0;

  running = false;

  observers = [];

  constructor(maxRunning = 1) {
    this.maxRunning = maxRunning;
  }

     
                               
                            
     
  push(callback) {
    const p = new Promise((resolve, reject) => {
      this.items.push(() => {
        return Promise.resolve(callback()).then(resolve);
      });
    });

    this.run();

    return p;
  }

  run() {
    if (!this.running) {
      this.running = true;
    }

    this.pop();
  }

  pop() {
    const callback = this.items.shift();

                              
    if (!callback) {
      this.running = false;
      return Promise.resolve();
    }

                                                            
    if (this.currentRunning >= this.maxRunning) {
      this.items.unshift(callback);
      return Promise.resolve();
    }

    this.currentRunning++;

    this.notice();

    return callback()
      .then((v) => {
        this.endPop();
        return v;
      })
      .catch((e) => {
        this.endPop();

        return Promise.reject(e);
      });
  }

  endPop() {
    this.currentRunning--;
    this.notice();
    this.pop();
  }

  clear() {
    this.items = [];

    this.notice();

    return this;
  }

     
                       
     
  isEmpty() {
    return this.items.length === 0;
  }

     
                      
     
  get length() {
    return this.items.length;
  }

  peek() {
    return this.items;
  }

     
                              
                                                                
                                  
     
  observe(handler, options = {}) {
    this.observers.push({
      handler,
      once: options.once || false
    });

    return () => {
      this.off(handler);
    };
  }

     
                              
                                                
                                  
     
  once(handler, options = {}) {
    options.once = true;

    return this.observe(handler, options);
  }

     
                              
                                                                
                                  
     
  onEnd(callback, options = {}) {
    return this.observe((queue, length, running) => {
      if (length === 0 && running === 0) {
        callback(queue, length, running);
      }
    });
  }

  notice() {
    this.observers.forEach((observer) => {
      observer.handler(this, this.length, this.currentRunning);
    });

    this.observers = this.observers.filter((observer) => observer.once !== true);

    return this;
  }

  off(callback = undefined) {
    if (callback === undefined) {
      this.observers = [];
      return this;
    }

    this.observers = this.observers.filter((observer) => observer.handler !== callback);
    return this;
  }
}
