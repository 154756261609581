   
                           
  
                                                   
                          
   

export default class UnicornLang {
  keys = {};

  static get is() { return 'lang'; }

  static install(app, options = {}) {
    const $lang = app.$lang = new this(app);

    app.__ = $lang.__.bind($lang);
    app.trans = $lang.trans.bind($lang);
  }

  constructor(app) {
    this.app = app;
  }

     
                         
                         
                      
     
  __(text, ...args) {
    return this.trans(text, ...args);
  }

     
                        
    
                         
                         
                      
     
  trans(text, ...args) {
    const key = this.normalize(text);

    let translated = this.find(key) || '';

    if (args.length) {
      translated = this.sprintf(translated, ...args);
    }

    return translated !== '' ? translated : this.wrapDebug(text, false);
  }

     
                             
                         
                        
     
  sprintf(text, ...args) {
    return this.app.vsprintf(text, args);
  }

     
               
                        
                           
     
  find(key) {
    const langs = this.app.data('unicorn.languages') || {};

    if (langs[key]) {
      return langs[key];
    }

    return null;
  }

     
                      
                        
                       
     
  has(key) {
    const langs = this.app.data('unicorn.languages');

    return langs[key] !== undefined;
  }

     
                      
    
                        
                          
    
                   
     
  add(key, value) {
    const data = this.app.data('unicorn.languages') || {};

    data[this.normalize(key)] = value;

    this.app.data('unicorn.languages', data);

    return this;
  }

     
                                   
    
                         
    
                     
     
  normalize(text) {
    return text.replace(/[^A-Z0-9]+/ig, '.');
  }

     
                         
                             
                      
     
  wrapDebug(text, success) {
    if (this.app.isDebug()) {
      if (success) {
        return '**' + text + '**';
      }

      return '??' + text + '??';
    }

    return text;
  }
}
