   
                           
  
                                                   
                          
   

import { parse, stringify } from 'qs';

export default class UnicornRouter {
  keys = {};

  static get is() { return 'router'; }

  static install(app, options = {}) {
    const $router = app.$router = new this(app);

    app.route = $router.route.bind($router);
  }

  constructor(app) {
    this.app = app;
  }

     
                 
    
                 
               
    
                             
     
  add(route, url) {
    const data = this.app.data('unicorn.routes') || {};
    data[route] = url;

    this.app.data('unicorn.routes', data);

    return this;
  }

     
               
    
                          
                     
                                    
     
  route(route, query = null) {
    const source = route;
    const extract = this.extractRoute(source);
    route = extract.route;
    let path = extract.path;
    const routes = this.app.data('unicorn.routes') || {};

    let url = routes[route];

    if (url == null) {
      if (!route.startsWith('@')) {
        route = '@' + route;
      } else {
        route = route.substr(1);
      }
    }

    url = routes[route];

    if (url == null) {
      throw new Error(`Route: "${source}" not found`);
    }

                  
    if (path) {
      const { route: u1, path: u1q } = this.extractRoute(url, '?');
      const { route: u2, path: u2q } = this.extractRoute(path, '?');

      url = u1 + '/' + u2;

      if (u1q || u2q) {
        const q = [ u1q, u2q ].filter(u => u).join('&');
        url += '?' + q;
      }
    }

    return this.addQuery(url, query);
  }

     
                          
                        
                                               
     
  extractRoute(route, sep = '/') {
    if (route.indexOf(sep) === -1) {
      return { route, path: '' }
    }

    const segments = route.split(sep);

    route = segments.shift();
    const path = segments.join(sep);

    return { route, path };
  }

     
                          
                       
     
  has(route) {
    return undefined !== this.app.data('unicorn.routes')[route];
  }

     
                        
                     
                      
     
  addQuery(url, query = null) {
    if (query === null) {
      return url;
    }

    const params = {};

    for (let k in query) {
      const v = query[k];

      const placeholder = `{${k}}`;

      if (url.indexOf(placeholder) !== -1) {
        url = url.replace(
          new RegExp(`${placeholder}`, 'g'),
          v
        );
        delete query[k];
      }

      const encodedPlaceholder = encodeURIComponent(`{${k}}`);

      if (url.indexOf(encodedPlaceholder) !== -1) {
        url = url.replace(
          new RegExp(`${encodedPlaceholder}`, 'g'),
          v
        );
        delete query[k];
      }
    }

    if (Object.keys(query).length === 0) {
      return url;
    }

    const queryString = stringify(query);

    return url + (/\?/.test(url) ? `&${queryString}` : `?${queryString}`);
  }

     
                                
                      
     
  parseQuery(queryString) {
    return parse(queryString);
  }

     
                          
                      
     
  buildQuery(query) {
    return stringify(query);
  }

     
                                
                    
     
  push(data) {
    if (typeof data === 'string') {
                                                   
      data = { uri: data };
    }

    window.history.pushState(
      data.state || null,
      data.title || null,
      data.uri || this.route(data.route, data.params),
    );

    return this;
  }

     
                                
                             
     
  replace(data) {
    if (typeof data === 'string') {
                                                   
      data = { uri: data };
    }

    window.history.replaceState(
      data.state || null,
      data.title || null,
      data.uri || this.route(data.route, data.params),
    );

    return this;
  }

     
                   
     
  state() {
    return window.history.state;
  }

  back() {
    window.history.back();
  }

  forward() {
    window.history.forward();
  }

     
                        
     
  go(num) {
    window.history.go(num);
  }
}
