   
                           
  
                                                  
                          
   

import { defaultsDeep, each } from 'lodash-es';
import 'sprintf-js';
import { defData, prepareData } from './../utilities.js';

const domReady = null;
const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export default class UnicornHelper {
  static get is() {
    return 'helper';
  }

  static install(app, options = {}) {
    const helper = app.$helper = new this(app);

    app.domready = helper.domready.bind(helper);
    app.selectOne = helper.selectOne.bind(helper);
    app.selectAll = helper.selectAll.bind(helper);
    app.each = helper.selectAll.bind(helper);
    app.getBoundedInstance = helper.getBoundedInstance.bind(helper);
    app.getBoundedInstanceList = helper.getBoundedInstanceList.bind(helper);
    app.module = helper.module.bind(helper);
    app.h = helper.h;
    app.html = helper.html;
    app.$get = helper.$get;
    app.$set = helper.$set;
    app.delegate = helper.delegate.bind(helper);
    app.debounce = helper.debounce.bind(helper);
    app.throttle = helper.throttle.bind(helper);
    app.isDebug = helper.isDebug.bind(helper);
    app.confirm = helper.confirm.bind(helper);
    app.alert = helper.alert.bind(helper);
    app.numberFormat = helper.numberFormat;
    app.sprintf = sprintf;
    app.vsprintf = vsprintf;
    app.genRandomString = helper.genRandomString;
    app.defaultsDeep = helper.defaultsDeep;
  }

  constructor(app) {
    this.app = app;
  }

     
                                             
                               
    
                      
     
  domready(callback = null) {
    let promise = domReady || new Promise((resolve) => {
                                        
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
                                      
        setTimeout(resolve, 0);
      } else {
        document.addEventListener('DOMContentLoaded', resolve);
      }
    });

    if (callback) {
      promise = promise.then(callback);
    }

    return promise;
  }

     
                                
                            
     
  selectOne(ele) {
    if (typeof ele === 'string') {
      ele = document.querySelector(ele);
    }

    return prepareData(ele);
  }

     
                                            
                               
                                             
     
  selectAll(ele, callback) {
    if (typeof ele === 'string') {
      ele = document.querySelectorAll(ele);
    }

    const resultSet = [].slice.call(ele);

    if (callback) {
      return resultSet.map(callback);
    }

    return resultSet;
  }

  each(collection, iteratee) {
    return each(collection, iteratee);
  }

     
                                     
                         
                                              
                 
     
  getBoundedInstance(selector, name, callback = () => null) {
    const element = this.selectOne(selector);

    if (!element) {
      return null;
    }

    return defData(element, name, callback);
  }

     
                                                 
                         
                                              
                 
     
  getBoundedInstanceList(selector, name, callback = () => null) {
    return this.app.selectAll(selector, (ele) => {
      return this.getBoundedInstance(ele, name, callback);
    });
  }

  module(ele, name, callback = () => null) {
    if (typeof ele === 'string') {
      return this.getBoundedInstanceList(ele, name, callback);
    }

    if (ele instanceof HTMLElement) {
      return this.getBoundedInstance(ele, name, callback);
    }

    return this.getBoundedInstanceList(ele, name, callback);
  }

     
                            
                                           
                                    
                       
     
  h(element, attrs = {}, content = null) {
    const ele = document.createElement(element);

    for (let i in attrs) {
      const v = attrs[i];

      ele.setAttribute(i, v);
    }

    if (content !== null) {
      ele.innerHTML = content;
    }

    return ele;
  }

     
                         
                       
     
  html(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.children[0];
  }

     
                   
                         
                 
     
  get(obj, path) {
    const keys = Array.isArray(path) ? path : path.split('.');

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (!obj || !obj.hasOwnProperty(key)) {
        obj = undefined;
        break;
      }

      obj = obj[key];
    }

    return obj;
  }

     
                   
                         
                     
                 
     
  set(obj, path, value) {
    const keys = Array.isArray(path) ? path : path.split('.');
    let i;

    for (i = 0; i < keys.length - 1; i++) {
      const key = keys[i];

      if (!obj.hasOwnProperty(key)) {
        obj[key] = {};
      }

      obj = obj[key];
    }

    obj[keys[i]] = value;

    return value;
  }

     
                                         
    
                                                                            
    
                                    
                                     
                              
                                           
                                  
     
  delegate(wrapper, selector, eventName, callback) {
    if (typeof selector === 'undefined' || selector === '') {
      throw new Error('The provided selector is empty.');
    }

    if (typeof callback === 'undefined' || typeof callback !== 'function') {
      throw new Error('Please specify an callback.');
    }

    const delegationSelectorsMap = {};

    wrapper = this.app.selectOne(wrapper);

    wrapper.addEventListener(eventName, function (event) {
      let element = event.target;
      let forceBreak = false;

      while (element && element !== wrapper) {
        for (const selector in delegationSelectorsMap) {
          if (element.matches(selector)) {
            event.stopPropagation = function () {
              forceBreak = true;
            };
            Object.defineProperty(
              event,
              'currentTarget',
              {
                get() {
                  return element;
                }
              }
            );

            const callbackList = delegationSelectorsMap[selector];

            callbackList.forEach(function (callback) {
              callback(event);
            });
          }
        }

        if (forceBreak) {
          break;
        }

        element = element.parentElement;
      }
    });

    if (!delegationSelectorsMap[selector]) {
                                     
      delegationSelectorsMap[selector] = [callback];
    } else {
      delegationSelectorsMap[selector].push(callback);
    }

    return function unsubscribe() {
      if (!delegationSelectorsMap[selector]) {
        return;
      }

      if (delegationSelectorsMap[selector].length >= 2) {
        delegationSelectorsMap[selector] = delegationSelectorsMap[selector].filter(cb => cb !== callback);
      } else {
        delete delegationSelectorsMap[selector];
      }
    };
  }

     
                              
                         
                        
     
  debounce(handler, wait = 1) {
    let timer, result;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => result = handler.call(this, ...args), wait);
      return result;
    };
  }

     
                              
                         
                        
     
  throttle(handler, wait = 1) {
    let timer, result;
    return function(...args) {
      if (!timer) {
        return result = handler.call(this, ...args);
      }

      clearTimeout(timer);
      timer = setTimeout(() => timer = undefined, wait);
      return result;
    };
  }

  isDebug() {
    return Boolean(this.app.data('windwalker.debug'));
  }

     
                   
    
                              
    
                      
     
  confirm(message) {
    message = message || 'Are you sure?';

    return new Promise((resolve) => {
      resolve(confirm(message));
    });
  }

     
                          
                         
                         
                                
     
  alert(title, text = '', type = 'info') {
    if (text) {
      title += ' | ' + text;
    }

    alert(title);

    return Promise.resolve(true);
  }

  nextTick(callback = () => {}) {
    return Promise.resolve().then(callback);
  }

  addUriBase(uri, type = 'path') {
    if (uri.substr(0, 2) === '/\/' || uri.substr(0, 4) === 'http') {
      return uri;
    }

    return this.app.asset(type) + '/' + uri;
  }

     
                                     
    
                                  
                                    
                                    
                                        
                      
     
  numberFormat(number, decimals = 0, decPoint = '.', thousandsSep = ',') {
    number = Number(number);

    const str = number.toFixed(decimals ? decimals : 0).toString().split('.');
    const parts = [];

    for (var i = str[0].length; i > 0; i -= 3) {
      parts.unshift(str[0].substring(Math.max(0, i - 3), i));
    }

    str[0] = parts.join(thousandsSep ? thousandsSep : ',');

    return str.join(decPoint ? decPoint : '.');
  }

     
                                                                               
                           
                      
     
  genRandomString(length) {
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  defaultsDeep(...args) {
    return defaultsDeep(...args);
  }
}
